import { useStaticQuery, graphql } from "gatsby"
export const  useEventsData = () => {
  const data = useStaticQuery(
    graphql`
      {
        pagesEventsYaml {
          heading {
            headingText
            subHeadingText
            subHeadingMaxWidth
          }
          events {
            content
          }
        }
      }
    `
  )
  return data.pagesEventsYaml
}